import './index.css'
import { useState, useCallback, useEffect, useRef } from 'react'
import KeyboardBtns from './keyboardBtns'
import CustomBtn from './customBtn'
import { getRem, getWindowSizeForLandscape, safeParse } from '../../common/util'
import Dialog from '../dialog'
import { savePanelJson, getInitialPanelJson } from './service'
import { MouseBtns } from './mouseBtns'
import { parseOpJson, restoreOpJson } from '../../common/player'

const InitialScale = 1
const InitialOpacity = 0.6
const InitialProps = {
    scale: 0,
    opacity: 0,
    type: 0,
    name: '',
    key: '',
    value: 0,
    btnType: 'keyboard'
}
const getValueTxt = n => {
    return `${Math.round(n * 100)}%`
}
const getBtnInitialPosition = btnType => {
    const { width, height } = getWindowSizeForLandscape()
    const rem = getRem()
    const size = btnType === 'joystick' ? 1.32 : 0.334
    return { left: Math.round((width - rem * size * InitialScale) / 2), top: Math.round((height - rem * size * InitialScale) / 2) }
}

function CustomPanel({ close, gameId, mode = 'client' }) {
    const firstLoaded = useRef('')
    const [btnTypeIndex, setBtnTypeIndex] = useState(0)
    const [btnList, setBtnList] = useState(() => {
        if (mode !== 'client') {
            return []
        }
        const result = getInitialPanelJson(gameId)
        firstLoaded.current = JSON.stringify(result)
        return result
    })
    const [btnIndex, setBtnIndex] = useState(-1)
    const [isCreateMode, toggleCreateMode] = useState(false)
    const [domReady, toggleDomReady] = useState(false)
    const [currentProps, setCurrentProps] = useState(InitialProps)
    const [showSaveDialog, toggleSaveDialog] = useState(false)
    const [showQuitDialog, toggleQuitDialog] = useState(false)
    const [showResetDialog, toggleResetDialog] = useState(false)
    const [putAway, togglePutAway] = useState(false)
    const [showMacro, toggleMacro] = useState(false)
    const [validMacro, toggleMacroValid] = useState(false)
    const [macroList, setMacroList] = useState([{}, {}, {}, {}])
    const chooseBtn = useCallback((index, props) => {
        setBtnIndex(index)
        setCurrentProps(props)
    }, [])

    useEffect(() => {
        toggleDomReady(true)
    }, [])

    useEffect(() => {
        if (mode === 'server') {
            const listener = message => {
                const data = safeParse(message.data)
                if (data && data.type === 'INIT_PANEL_JSON') {
                    const parsedData = restoreOpJson(data.data)
                    setBtnList(parsedData)
                    if (!firstLoaded.current) {
                        firstLoaded.current = JSON.stringify(parsedData)
                    }
                }
            }
            window.addEventListener('message', listener)
            window.parent.postMessage(JSON.stringify({ type: 'INIT_PANEL_JSON' }), '*')
            return () => {
                window.removeEventListener('message', listener)
            }
        }
    }, [mode])

    useEffect(() => {
        const list = macroList.filter(i => !!i.id)
        toggleMacroValid(list.length > 1)
    }, [macroList])

    const delBtn = () => {
        const chosen = btnList[btnIndex]
        if (chosen) {
            setCurrentProps(InitialProps)
            setBtnIndex(-1)
            setBtnList(btnList.filter(b => b.id !== chosen.id))
        }
    }

    const moveEnd = (index, position) => {
        const chosen = btnList[index]
        const list = btnList.slice()
        list[index] = { ...chosen, position }
        setBtnList(list)
    }

    const createBtnFinally = (name, key, value, btnType) => {
        const newBtn = Object.create(null)
        newBtn.id = Date.now() + ''
        newBtn.props = {
            scale: InitialScale,
            opacity: InitialOpacity,
            type: 0,
            name,
            key,
            value,
            btnType
        }
        const { left, top } = getBtnInitialPosition(btnType)
        newBtn.position = {
            x: left,
            y: top
        }
        chooseBtn(btnList.length, newBtn.props)
        setBtnList(btnList.concat(newBtn))
    }

    const createBtn = (name, key, value, btnType) => {
        if (showMacro) {
            if (btnType !== 'keyboard') {
                return
            }
            const list = macroList.filter(i => !!i.id)
            if (list.length === macroList.length) {
                return
            }
            const newBtn = Object.create(null)
            newBtn.id = Date.now() + ''
            newBtn.name = name
            newBtn.key = key
            newBtn.value = value
            const newList = macroList.slice()
            newList[list.length] = newBtn
            setMacroList(newList)
        } else {
            createBtnFinally(name, key, value, btnType)
            exitCreateMode()
        }
    }

    const updateBtnProps = props => {
        const chosen = btnList[btnIndex]
        if (chosen) {
            const list = btnList.slice()
            list[btnIndex] = { ...chosen, props }
            setBtnList(list)
        }
    }

    const changeBtnType = type => {
        if (type === currentProps.type) {
            return
        }
        const newProp = { ...currentProps, type }
        setCurrentProps(newProp)
        updateBtnProps(newProp)
    }

    const inputBtnName = e => {
        const { value } = e.target
        const newProp = { ...currentProps, name: value }
        setCurrentProps(newProp)
        updateBtnProps(newProp)
    }

    const changeScale = value => {
        const newProp = { ...currentProps, scale: currentProps.scale + value }
        setCurrentProps(newProp)
        updateBtnProps(newProp)
    }

    const changeOpacity = value => {
        const newProp = { ...currentProps, opacity: Number((currentProps.opacity + value).toFixed(1)) }
        setCurrentProps(newProp)
        updateBtnProps(newProp)
    }

    const addScale = () => {
        if (currentProps.scale >= 1.25) {
            return
        }
        changeScale(0.125)
    }

    const addOpacity = () => {
        if (currentProps.opacity >= 1) {
            return
        }
        changeOpacity(0.1)
    }

    const subScale = () => {
        if (currentProps.scale <= 0.75) {
            return
        }
        changeScale(-0.125)
    }

    const subOpacity = () => {
        if (currentProps.opacity <= 0.1) {
            return
        }
        changeOpacity(-0.1)
    }

    const toCreateMode = () => {
        toggleCreateMode(true)
    }

    const exitCreateMode = () => {
        toggleCreateMode(false)
    }

    const closeSetting = () => {
        toggleSaveDialog(false)
    }

    const closeQuit = () => {
        toggleQuitDialog(false)
    }

    const save = () => {
        if (mode === 'server') {
            closeSetting()
            // console.log(restoreOpJson(parseOpJson(btnList)))
            window.parent.postMessage(JSON.stringify({ type: 'SAVE_PANEL_JSON', data: parseOpJson(btnList) }), '*')
            return
        }
        savePanelJson(gameId, btnList)
        close()
    }

    const goToQuit = () => {
        mode === 'client' && toggleQuitDialog(true)
    }

    const goToReset = () => {
        toggleResetDialog(true)
    }

    const cancelReset = () => {
        toggleResetDialog(false)
    }

    const doReset = () => {
        setCurrentProps(InitialProps)
        setBtnIndex(-1)
        setBtnList(safeParse(firstLoaded.current))
        cancelReset()
    }

    const goToSave = () => {
        toggleSaveDialog(true)
    }

    const togglePutAwayState = () => {
        togglePutAway(!putAway)
    }

    const goToSetMacro = () => {
        setBtnTypeIndex(0)
        toCreateMode()
        toggleMacro(true)
    }

    const backFromMacro = () => {
        toggleMacro(false)
        setMacroList([{}, {}, {}, {}])
    }

    const delMacroItem = id => {
        setMacroList(macroList.filter(i => i.id !== id).concat({}))
    }

    const saveMacro = () => {
        if (!validMacro) {
            return
        }
        const items = macroList.filter(i => !!i.id)
        const values = items.map(i => i.value + '').join('，')
        const keys = items.map(i => i.key).join('，')
        createBtnFinally('组合键', keys, values, 'macro')
        backFromMacro()
        exitCreateMode()
    }

    return (
        <div className='app-play-screen-mask'>
            <div className='cp-main-container'>
                {
                    btnList.map((btn, i) => (
                        <CustomBtn key={btn.id} index={i} props={btn.props} position={btn.position} chooseBtn={chooseBtn} moveEnd={moveEnd} btnIndex={btnIndex}></CustomBtn>
                    ))
                }
            </div>
            <div className={putAway ? 'cp-top-bar-container away' : 'cp-top-bar-container'}>
                {
                    showMacro
                        ? <div className='cp-top-macro-container'>
                            <i className='cp-top-macro-back' onClick={backFromMacro}></i>
                            <i className='cp-top-macro-split'></i>
                            <p className='cp-top-macro-tip'>选择下方按键进行添加，至少两个</p>
                            <button className={validMacro ? 'cp-top-macro-save-btn' : 'cp-top-macro-save-btn disabled'} onClick={saveMacro}>保存组合键</button>
                            <div className='cp-top-macro-content'>
                                {
                                    macroList.map((item, i) => (<>
                                        <div className='cp-top-macro-item'>
                                            {
                                                !!item.id && <>
                                                    <p className='cp-top-macro-item-txt'>{item.name}</p>
                                                    <i className='cp-top-macro-item-del' onClick={() => delMacroItem(item.id)}></i>
                                                </>
                                            }
                                        </div>
                                        {
                                            i < macroList.length - 1 && <i className='cp-top-macro-plus'></i>
                                        }
                                    </>))
                                }
                            </div>
                        </div>
                        : <>
                            <div className='cp-top-bar-btns'>
                                <button className='cp-top-bar-close' onClick={goToQuit}></button>
                                <span className='cp-top-bar-split'></span>
                                <span className='cp-top-bar-tip'>拖动按键<span className='highlight'>调位置</span>，点击按键设置<span className='highlight'>大小名称</span></span>
                                <button className='cp-top-bar-btn create' onClick={toCreateMode}>新增按键</button>
                                <button className='cp-top-bar-btn create' onClick={goToSetMacro}>组合按键</button>
                                <span className='cp-top-bar-split closer'></span>
                                <button className='cp-top-bar-btn default' onClick={goToReset}>还原默认</button>
                                <button className='cp-top-bar-btn save' onClick={goToSave}>保存</button>
                            </div>
                            <div className='cp-top-op-wrapper'>
                                <div className='cp-top-op'>
                                    <span className='cp-top-op-tip'>按键大小</span>
                                    <div className='cp-top-op-number-wrapper'>
                                        <button className='cp-top-op-number-btn add' onClick={addScale}></button>
                                        <p className='cp-top-op-number-txt'>{getValueTxt(currentProps.scale)}</p>
                                        <button className='cp-top-op-number-btn sub' onClick={subScale}></button>
                                    </div>
                                    <span className='cp-top-op-tip'>透明度</span>
                                    <div className='cp-top-op-number-wrapper'>
                                        <button className='cp-top-op-number-btn add' onClick={addOpacity}></button>
                                        <p className='cp-top-op-number-txt'>{getValueTxt(currentProps.opacity)}</p>
                                        <button className='cp-top-op-number-btn sub' onClick={subOpacity}></button>
                                    </div>
                                    <span className='cp-top-op-tip'>按键交互</span>
                                    <div className='cp-top-op-checkbox-wrapper' onClick={() => changeBtnType(0)}>
                                        <i className={currentProps.type === 0 ? 'cp-top-op-checkbox active' : 'cp-top-op-checkbox'}></i>
                                        <span>单击</span>
                                    </div>
                                    <div className='cp-top-op-checkbox-wrapper' onClick={() => changeBtnType(1)}>
                                        <i className={currentProps.type === 1 ? 'cp-top-op-checkbox active' : 'cp-top-op-checkbox'}></i>
                                        <span>长按</span>
                                    </div>
                                    <span className='cp-top-op-tip'>按键名称</span>
                                    <input className='cp-top-op-input' value={currentProps.name} onChange={inputBtnName} maxLength={16}></input>
                                    {/* <button className='cp-top-op-btn'>修改键位</button> */}
                                    <button className='cp-top-op-btn' onClick={delBtn}>删除</button>
                                </div>
                            </div>
                            <i className='cp-top-bar-slide' onClick={togglePutAwayState}></i>
                        </>
                }
            </div>
            <div className={
                domReady
                    ? isCreateMode
                        ? 'cp-btns-box-wrapper with-ani show'
                        : 'cp-btns-box-wrapper with-ani'
                    : 'cp-btns-box-wrapper'
            }>
                <div className='cp-btns-box-header'>
                    <div className='cp-btns-box-toggle' onClick={exitCreateMode}></div>
                    <div className={`cp-btns-box-types index-${btnTypeIndex}`}>
                        <div className='type-1' onClick={() => setBtnTypeIndex(0)}>键盘</div>
                        <div className='type-2' onClick={() => setBtnTypeIndex(1)}>鼠标/摇杆</div>
                    </div>
                </div>
                <div className={btnTypeIndex === 0 ? 'cp-btns-box-content show' : 'cp-btns-box-content'}>
                    <div className='cp-btns-box-keyboard-grid'>
                        {
                            KeyboardBtns.map(k => (
                                <div className={`cp-btns-box-keyboard-item id-${k.id}`} key={k.id} onClick={() => createBtn(k.name, k.name, k.value, 'keyboard')}>{k.name}</div>
                            ))
                        }
                    </div>
                </div>
                <div className={btnTypeIndex === 1 ? 'cp-btns-box-content show' : 'cp-btns-box-content'}>
                    <div className='cp-btns-box-mouse-grid'>
                        {
                            MouseBtns.map(m => (
                                <div className='cp-btns-box-mouse-item' key={m.id} onClick={() => createBtn(m.txt, m.name, m.value, 'mouse')}>
                                    <div className='cp-btns-box-mouse-btn'>
                                        <i className={`cp-btns-box-mouse-btn-icon ${m.name}`}></i>
                                    </div>
                                    <p className='cp-btns-box-mouse-btn-txt'>{m.txt}</p>
                                </div>
                            ))
                        }
                        {
                            mode !== 'client' && <>
                                <div className='cp-btns-box-mouse-item' onClick={() => createBtn('WASD', 'WASD', 70, 'joystick')}>
                                    <div className='cp-btns-box-mouse-btn wasd'></div>
                                    <p className='cp-btns-box-mouse-btn-txt'>WSAD轮盘</p>
                                </div>
                                <div className='cp-btns-box-mouse-item' onClick={() => createBtn('↑↓←→', '↑↓←→', 71, 'joystick')}>
                                    <div className='cp-btns-box-mouse-btn sxzy'></div>
                                    <p className='cp-btns-box-mouse-btn-txt'>方向轮盘</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                showSaveDialog && <Dialog title={'保存当前自定义设置？'} className={'zIndex1'} cancelFunc={closeSetting} okFunc={save}></Dialog>
            }
            {
                showQuitDialog && <Dialog title={'设置未保存，确定离开？'} className={'zIndex1'} cancelFunc={closeQuit} okFunc={close}></Dialog>
            }
            {
                showResetDialog && <Dialog title={'还原为默认设置？'} className={'zIndex1'} cancelFunc={cancelReset} okFunc={doReset}></Dialog>
            }
        </div>
    )
}

export default CustomPanel