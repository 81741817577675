import './index.css'
import '../../pages/pay/index.css'
import { useState, useEffect, useRef, useContext } from 'react'
import AppContext from '../../common/context'
import { getNewGuyGiftList, orderNewGuyGift } from './service'
import PubSub from 'pubsub-js'
import { EventTip, EventUserInvalid, EventPayStart, EventPayEnd } from '../../common/event'
import { getHour } from '../../common/util'
import { motion } from 'framer-motion'
import { SlideFromBottom } from '../../animation'
import { goToPay } from '../../config'

const getItemDesc = item => {
    const { durationTime, durationType, vipDay } = item
    return `${vipDay}天会员+${durationType === 1 ? `${getHour(durationTime)}永久时长` : `${durationTime}天无限畅玩`}`
}

function NewGuyGift({ close, info }) {
    const { setAgreementType, toggleAgreement } = useContext(AppContext)
    const [showEntry, toggleEntry] = useState(true)
    const [giftList, setGiftList] = useState([])
    const [chosen, setChosen] = useState(info)
    const [payTypeIndex, setPayTypeIndex] = useState(0)
    const submitFlag = useRef(false)

    useEffect(() => {
        if (!showEntry) {
            getNewGuyGiftList().then(data => {
                setGiftList(data)
                setChosen(data[0] || {})
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            })
        }
    }, [showEntry, info.id])

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)
        const listener_ = PubSub.subscribe(EventPayEnd, close)

        return () => {
            PubSub.unsubscribe(listener)
            PubSub.unsubscribe(listener_)
        }
    }, [close])

    const goToBuy = () => {
        toggleEntry(false)
    }

    const submit = () => {
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        const ptype = payTypeIndex === 0 ? 2 : 1
        orderNewGuyGift(chosen.id, ptype).then(data => {
            goToPay(data.payResult, ptype)
            PubSub.publish(EventPayStart, { type: 2, orderid: data.orderid })
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const goToRechargeAgreement = () => {
        setAgreementType(3)
        toggleAgreement(true)
    }

    return showEntry ? (
        <div className='app-page-container dialog zIndex2'>
            <div className='app-new-guy-gift-container'>
                {/* <div className='app-new-guy-gift-promo'></div> */}
                <i className='app-new-guy-gift-close' onClick={close}></i>
                <div className='app-new-guy-gift-total-price'>
                    <span className='unit'>&yen;</span>
                    {info.androidPrice}
                </div>
                <div className='app-new-guy-gift-price'>
                    <span className='app-new-guy-gift-label'>仅需</span>
                    {info.conPrice}元/小时
                </div>
                <div>
                    <div className='app-new-guy-right-item'>
                        <span className='app-new-guy-right-value'>{info.vipDay}</span>
                        <span className='app-new-guy-right-txt'>天会员</span>
                    </div>
                    {
                        info.durationType === 1
                            ? <div className='app-new-guy-right-item'>
                                <span className='app-new-guy-right-value'>{getHour(info.durationTime, false)}</span>
                                <span className='app-new-guy-right-txt'>小时永久时长</span>
                            </div>
                            : <div className='app-new-guy-right-item'>
                                <span className='app-new-guy-right-value'>{info.durationTime}</span>
                                <span className='app-new-guy-right-txt'>天无线畅玩</span>
                            </div>
                    }
                    <div className='app-new-guy-right-item'>
                        <span className='app-new-guy-right-txt'>高峰通道 快速升级</span>
                    </div>
                </div>
                <div className='app-new-guy-gift-btn-wrapper'>
                    <button className='app-new-guy-gift-btn' onClick={goToBuy}></button>
                </div>
            </div>
        </div>
    ) : (
        <motion.div className='app-page-container zIndex2' {...SlideFromBottom}>
            <div className='app-page-window'>
                <div className='app-whole-page app-new-guy-pay-container'>
                    <i className='app-popup-close' onClick={close}></i>
                    <div>
                        {
                            giftList.map(item => (
                                <div className={item.id === chosen.id ? 'app-new-guy-pay-item active' : 'app-new-guy-pay-item'} key={item.id} onClick={() => setChosen(item)}>
                                    <div className='app-new-guy-pay-item-main'>
                                        <p className='app-new-guy-pay-item-name'>{getItemDesc(item)}</p>
                                    </div>
                                    <p className='app-new-guy-pay-item-price'>&yen;{item.conPrice}<span className='app-new-guy-pay-item-unit'>/小时</span></p>
                                    {
                                        item.id === info.id && <div className='app-new-guy-gift-hot'>
                                            <p>91%用户购买</p>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className='app-pay-types-wrapper index-3'>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(0)}>
                            <i className={payTypeIndex === 0 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <i className='app-pay-type-ali-icon'></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon ali'></i>支付宝</p>
                        </div>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(1)}>
                            <i className={payTypeIndex === 1 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon wechat'></i>微信</p>
                        </div>
                    </div>
                    <div className='app-new-guy-pay-promo'></div>
                    <div className='app-new-guy-pay-btn-wrapper'>
                        <button className='app-new-guy-pay-btn' onClick={submit}>立即购买</button>
                        <p className='app-new-guy-pay-price'>
                            <span className='unit'>&yen;</span>
                            <span className='main'>{chosen.androidPrice}</span>
                            <span className='original'>&yen;{chosen.oldPrice}</span>
                        </p>
                    </div>
                    <p className='app-new-guy-pay-tip'>支付即视为阅读并同意<span className='highlight' onClick={goToRechargeAgreement}>《用户充值协议》</span></p>
                </div>
            </div>
        </motion.div>
    )
}

export default NewGuyGift