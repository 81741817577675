import './customBtn.css'
import { useState, useRef, useCallback, useEffect } from "react"
import { getRem, getWindowSizeForLandscape } from '../../common/util'

function CustomBtn({ index, props, position, chooseBtn, moveEnd, btnIndex }) {
    const [x, setX] = useState(position.x)
    const [y, setY] = useState(position.y)
    const dragState = useRef(false)
    const touchOriginX = useRef(0)
    const touchOriginY = useRef(0)
    const touchMaxX = useRef(0)
    const touchMaxY = useRef(0)
    const xRef = useRef(position.x)
    const yRef = useRef(position.y)
    const touchStart = useCallback(e => {
        dragState.current = true
        const { screenX, screenY } = e.touches[0]
        touchOriginX.current = screenX
        touchOriginY.current = screenY
        chooseBtn(index, props)
    }, [chooseBtn, index, props])
    const touchMove = useCallback(e => {
        if (dragState.current) {
            const { screenX, screenY } = e.touches[0]
            const deltaX = screenX - touchOriginX.current
            const deltaY = screenY - touchOriginY.current
            touchOriginX.current = screenX
            touchOriginY.current = screenY
            setX(ox => Math.min(Math.max(0, ox + deltaX), touchMaxX.current))
            setY(oy => Math.min(Math.max(0, oy + deltaY), touchMaxY.current))
        }
    }, [])

    useEffect(() => {
        const { width, height } = getWindowSizeForLandscape()
        const size = props.btnType === 'joystick' ? 1.32 : 0.334
        touchMaxX.current = width - getRem() * props.scale * size
        touchMaxY.current = height - getRem() * props.scale * size
        let fix = false
        if (xRef.current > touchMaxX.current) {
            fix = true
            setX(touchMaxX.current)
            xRef.current = touchMaxX.current
        }
        if (yRef.current > touchMaxY.current) {
            fix = true
            setY(touchMaxY.current)
            yRef.current = touchMaxY.current
        }
        fix && moveEnd(index, { x: xRef.current, y: yRef.current })
    }, [props.scale, props.btnType, index, moveEnd])

    const touchEnd = () => {
        dragState.current = false
        xRef.current = x
        yRef.current = y
        moveEnd(index, { x, y })
    }

    const mouseDown = e => {
        const { screenX, screenY } = e
        touchStart({ touches: [{ screenX, screenY }] })
    }

    const mouseMove = e => {
        const { screenX, screenY } = e
        touchMove({ touches: [{ screenX, screenY }] })
    }

    const getBtnClassName = () => {
        let name = `custom-btn ${props.btnType}`
        if (props.btnType === 'joystick') {
            name += props.value === 70 ? ' wasd' : ' sxzy'
        }
        if (btnIndex === index) {
            name += ' active'
        }
        return name
    }

    return (
        <div className={getBtnClassName()} style={{ left: `${x}px`, top: `${y}px`, transform: `scale(${props.scale})`, opacity: `${props.opacity}` }}
            onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchMove={touchMove} onMouseDown={mouseDown} onMouseMove={mouseMove} onMouseUp={touchEnd}>
            {
                props.btnType === 'keyboard' && <>
                    <p className='custom-btn-name'>{props.name}</p>
                    {/* <p className='custom-btn-key'>{props.key}</p> */}
                </>
            }
            {
                props.btnType === 'mouse' && <i className={`cp-btns-box-mouse-btn-icon ${props.key}`}></i>
            }
            {
                props.btnType === 'macro' && <p className='custom-btn-name'>{props.name}</p>
            }
        </div>
    )
}

export default CustomBtn