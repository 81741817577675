import './customBtn.css'
import { useState, memo } from 'react'
import { playerKeyDown, playerKeyUp, playerMouseDown, playerMouseUp } from '../../common/player'
import { getInitialPanelJson } from './service'
import { isString } from '../../common/util'

const DownKey = value => {
    if (isString(value)) {
        value.split('，').forEach(v => {
            DownKey(Number(v))
        })
    } else if (value < 0) {
        playerMouseDown(value === -2)
    } else {
        playerKeyDown(value)
    }
}

const UpKey = value => {
    if (isString(value)) {
        value.split('，').forEach(v => {
            UpKey(Number(v))
        })
    } else if (value < 0) {
        playerMouseUp(value === -2)
    } else {
        playerKeyUp(value)
    }
}

function GamePanel({ gameId }) {
    const [btnList] = useState(() => getInitialPanelJson(gameId))
    const [activeBtnIds, setActiveBtnIds] = useState([])

    const touchStart = (id, props) => {
        const { type, value } = props
        if (type === 0) {
            return DownKey(value)
        }
        if (activeBtnIds.includes(id)) {
            UpKey(value)
            setActiveBtnIds(activeBtnIds.filter(btnId => btnId !== id))
        } else {
            DownKey(value)
            setActiveBtnIds(activeBtnIds.concat(id))
        }
    }

    const touchEnd = props => {
        const { type, value } = props
        if (type === 0) {
            UpKey(value)
        }
    }

    const getBtnClassName = (id, type, btnType) => {
        let className = `custom-btn game-mode ${btnType}`
        if (type === 1 && activeBtnIds.includes(id)) {
            className += ' active'
        }
        return className
    }

    return (
        <div className='app-play-screen-mask readonly'>
            {
                btnList.map(btn => (
                    <div className={getBtnClassName(btn.id, btn.props.type, btn.props.btnType)} key={btn.id}
                        style={{ left: `${btn.position.x}px`, top: `${btn.position.y}px`, transform: `scale(${btn.props.scale})`, opacity: `${btn.props.opacity}` }}
                        onTouchStart={() => touchStart(btn.id, btn.props)} onTouchEnd={() => touchEnd(btn.props)}
                    >
                        {
                            btn.props.btnType === 'keyboard' && <>
                                <p className='custom-btn-name'>{btn.props.name}</p>
                                <p className='custom-btn-key'>{btn.props.key}</p>
                            </>
                        }
                        {
                            btn.props.btnType === 'mouse' && <i className={`cp-btns-box-mouse-btn-icon ${btn.props.key}`}></i>
                        }
                        {
                            btn.props.btnType === 'macro' && <p className='custom-btn-name'>{btn.props.name}</p>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default memo(GamePanel)